import { Controller } from "@hotwired/stimulus"

// Using ES6 import syntax
import hljs from 'highlight.js/lib/core'
import ruby from 'highlight.js/lib/languages/ruby'
import javascript from 'highlight.js/lib/languages/javascript'

// Then register the languages you need
hljs.registerLanguage('ruby', ruby);
hljs.registerLanguage('javascript', javascript);

export default class extends Controller {
  connect() {
    hljs.highlightAll()
  }
}
